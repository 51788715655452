import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Header from "../../components/header"

import { useInView } from "react-intersection-observer"
import Projects from "../../components/projects"

const Typography = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  return (
    <Layout>
      <Header />
      <div className="project">
        <div className="container-fluid subheader">
          <div className="row">
            <div className="col-lg-4">
              <h2>09. Font Design</h2>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <h2>2019–Now</h2>
            </div>
            <div className="col-lg-4">
              <h2>Typeface Design</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12 hero hero-typefaces">
              <h2 className="text-right">
                <Link to="/typefaces/el-centro">More Info ↗</Link>
              </h2>
              <h3>Tipographía</h3>
              <div className="row">
                <div className="col-12 col-md-3">
                  <p className="text-center">Name: El Centro</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Weight: Book</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Families: 01</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Year: 2019</p>
                </div>
              </div>
              <div className={`hero-footer ${inView}`}>
                <div className="row justify-content-between align-items-end">
                  <div className="col-lg-5 d-none d-md-block">
                    <h2>
                      <Link to="/projects/rifla">Previous Project(08) ↖</Link>
                    </h2>
                    <h2>
                      <Link to="/memories">
                        Memories(10) ↗
                      </Link>
                    </h2>
                  </div>
                  <div className="col-md-3 d-none d-md-block">
                    <h2>
                      <Link to="/">rg-rq.com</Link>
                    </h2>
                  </div>
                  <div className="offset-md-2 col-md-2">
                    <button onClick={scrollTop}>
                      <h2>
                        Back up
                        {inView ? (
                          <img
                            className="img-fluid"
                            src="/Up.svg"
                            alt="cheveron icon"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src="/Up-white.svg"
                            alt="cheveron icon"
                          />
                        )}
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid content">
          <div className="row type">
            <div className="col-12">
            <h2 className="text-right">
                <Link to="/typefaces/mola">More Info ↗</Link>
              </h2>
              <h3 className="mola">Los Angeles</h3>
              <div className="row">
                <div className="col-12 col-md-3">
                  <p className="text-center">
                    Name: <span className="mola">MOLA</span>
                  </p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Weight: Regular</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Families: 01</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Year: 2019</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row type">
            <div className="col-12">
              <h3 className="sonidero">SAMPUESANA</h3>
              <div className="row">
                <div className="col-12 col-md-3">
                  <p className="text-center">Name: Sonidero</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Weight: Tumbado</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Families: 01</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Year: 2019</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row type">
            <div className="col-12">
              <h3 className="nuevo-grotesque">Just do it</h3>
              <div className="row">
                <div className="col-12 col-md-3">
                  <p className="text-center">Name: Nuevo Grotesque</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Weight: Book</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Families: 01</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Year: 2019</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row type">
            <div className="col-12">
              <h3 className="fifa">GOLAZO</h3>
              <div className="row">
                <div className="col-12 col-md-3">
                  <p className="text-center">Name: FIFA</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Weight: Book</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Families: 01</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="text-center">Year: 2019</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-4">
              <p>Special Thank You</p>
            </div>
            <div className="col-lg-8">
              <p>Greg Lindy (Lux Typo)</p>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref}></div>
      <Projects />
    </Layout>
  )
}
export default Typography
